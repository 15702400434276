import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    disableInput: Boolean,
  };

  connect() {
    if (this.disableInputValue) {
      if (window.location.pathname.includes("/rights")) {
        var formElements = document.querySelectorAll(
          "#nettowelt_configuration_agent_attributes_agent_group_id, #nettowelt_configuration_settings_hidden"
        );
      } else {
        var formElements = document.querySelectorAll(
          "input, select, textarea, button"
        );
      }
      formElements.forEach(function (element) {
        element.setAttribute("disabled", true);
        element.style.cursor = "not-allowed";
      });
    }
  }
}
