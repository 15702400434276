import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offers--process-documents"
// This controller is only for process-document section of policy journey where we requires to send selected checkbox ids as a param to each action.
export default class extends Controller {
  static values = {
    onboarding: { type: Boolean, default: false },
  }

  get checkBoxes() {
    if (this.onboardingValue) {
      const rootElement = document.querySelector('[data-controller="checkbox-selection"]')
      return Array.from(rootElement.querySelectorAll('input[type="checkbox"]'));
    } else {
      return Array.from(this.element.querySelectorAll('input[type="checkbox"]'));
    }
  }

  async downloadDocuments(event) {
    event.preventDefault();

    const selectedIds = this.checkBoxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);

    if (selectedIds.length > 0) {
      const params = new URLSearchParams(selectedIds.map(id => `document_ids[]=${id}`).join('&'));
      const url = `${event.currentTarget.href}?${params}`;
      window.location.href = url;
    }
  }

  async appendParamsandSubmitRequest(event) {
    event.preventDefault();

    const form = event.target.closest("form")
    const url = new URL(form.action)

    const selectedIds = this.checkBoxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.dataset.unsignedDocumentId)
    selectedIds.forEach(id => url.searchParams.append('document_ids[]', id))
    form.action = url.toString()

    form.requestSubmit()
  }
}
